<template>
  <div>
    <el-collapse accordion>
      <el-collapse-item>
        <template #title>
          <i style="margin-left: 1rem;font-size: 1rem;font-weight: 600;margin-right: .5rem" class="el-icon-search"></i>筛选条件
        </template>
        <div class="flex-def flex-zCenter flex-cCenter">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="用户昵称">
              <el-input v-model="searchForm.nickname" placeholder="用户昵称"></el-input>
            </el-form-item>
            <el-form-item label="访问时间">
              <el-date-picker
                  v-model="searchForm.time_between"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="60"></el-table-column>
      <el-table-column prop="active.title" width="200" label="所属活动"></el-table-column>
      <el-table-column prop="member.nickname" label="昵称"></el-table-column>
      <el-table-column label="头像" width="50">
        <template #default="scope">
          <el-avatar shape="square" size="small" :src="scope.row.member ? scope.row.member.avatar : '' | tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="from_member.nickname" width="200" label="推荐用户"></el-table-column>
      <el-table-column prop="client_ip" label="ip"></el-table-column>
      <el-table-column label="访问时间" width="180">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "visit",
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        time_between:[],
      },
      loading:false,
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    resetSearch(){
      this.searchForm = {
        nickname:"",
        time_between:[],
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.log.visit({
        nickname : this.searchForm.nickname,
        time_between:this.searchForm.time_between,
        page : this.page
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>