<template>
  <div class="tab">
    <div class="tab-header y-pointer">
      <ul class="tab-item" ref="nav">
        <li
            :class="tabCls(item)"
            v-for="(item, index) in navList"
            :key="index"
            @click="handleChange(index)"
        >
          {{item.label}}
        </li>
      </ul>
      <div :class="activeLine ? 'bar-animated':''" class="active-bar-link" :style="barStyle"></div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "YTabs",
  model:{
    prop:"value",
    event:"change",
  },
  computed: {
    barStyle(){
      let style = {
        visibility: 'visible',
        width: `${this.barWidth}px`
      }
      style.transform = `translate3d(${this.barOffset}px, 0px, 0px)`;
      return style;
    },
  },
  props: {
    value: {
      type:String,
      default:"",
    },
    activeLine:{
      type:Boolean,
      default: false,
    }
  },
  provide() {
    return { TabsInstance: this };
  },
  watch: {
    value(){
      this.activeKey = this.value;
    },
    activeKey() {
      this.updateStatus();
      this.updateBar();
    }
  },
  data(){
    return{
      navList: [],
      activeKey: this.value,
      barWidth: 0,
      barOffset: 0
    }
  },
  methods:{
    handleChange(index) {
      this.activeKey = this.navList[index].name;
      const nav = this.navList[index];
      this.$emit('tab-click', nav.name)
      this.$emit("change",nav.name);
    },
    tabCls(item) {
      return [
        `tab-item-title`,
        {
          [ this.activeLine ? `tab-active-line`:`tab-active`]: item.name === this.activeKey
        }
      ];
    },
    getPanels() {
      const TabPanels = this.$children.filter(
          item => item.$options.name === "YTabPane"
      );
      TabPanels.sort((a, b) => {
        if (a.index && b.index) {
          return a.index > b.index ? 1 : -1;
        }
      });
      return TabPanels;
    },
    updateNav() {
      this.navList = [];
      this.getPanels().forEach((panel, index) => {
        this.navList.push({
          label: panel.label,
          name: panel.name || index
        });
        if (!panel.currentName) panel.currentName = index;
        if (index === 0) {
          if (!this.activeKey)
            this.activeKey = panel.currentName || index;
        }
      });
      this.updateStatus();
      this.updateBar();
    },
    updateBar() {
      this.$nextTick(() => {
        const index = this.getTabIndex(this.activeKey);
        if (!this.$refs.nav) return;
        const prevTabs = this.$refs.nav.querySelectorAll('.tab-item-title');
        const tab = prevTabs[index];
        this.barWidth = this.activeLine ? (tab? parseFloat(tab.offsetWidth): 0) / 4 : (tab? parseFloat(tab.offsetWidth): 0);
        if(index > 0){
          let offset = 0;
          for(let i = 0;i < index;i++){
            offset += prevTabs[i].offsetWidth
          }
          this.barOffset = offset;
        }else{
          this.barOffset = 0;
        }
        if (this.activeLine)this.barOffset += (this.barWidth*1.4);
      });
    },
    getTabIndex(name) {
      return this.navList.findIndex(nav => nav.name === name);
    },
    updateStatus() {
      const navs = this.getPanels();
      navs.forEach(
          tab => (tab.show = tab.currentName === this.activeKey)
      );
    }
  }
}
</script>

<style scoped>
@import "base.css";
</style>