<template>
  <div>
    <div class="flex-def flex-zBetween" style="margin-bottom: 1rem">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input v-model="searchForm.nickname" size="medium" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="奖励类型">
          <el-select v-model="searchForm.type">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="1级邀请订单" :value="1"></el-option>
            <el-option label="2级邀请订单" :value="5"></el-option>
            <el-option label="转盘抽奖" :value="2"></el-option>
            <el-option label="分享红包" :value="3"></el-option>
            <el-option label="推荐礼品" :value="4"></el-option>
            <el-option label="员工服务奖励" :value="6"></el-option>
            <el-option label="九宫格抽奖" :value="7"></el-option>
            <el-option label="盲盒抽奖" :value="8"></el-option>
            <el-option label="商家直推订单奖励" :value="9"></el-option>
            <el-option label="店员直推订单商家奖励" :value="10"></el-option>
            <el-option label="客户直推订单关联商家奖励" :value="11"></el-option>
            <el-option label="店员购买订单商家奖励" :value="12"></el-option>
            <el-option label="店员直推订单奖励" :value="13"></el-option>
            <el-option label="客户直推订单奖励-有关联商家" :value="14"></el-option>
            <el-option label="客户直推订单奖励-无关联商家" :value="15"></el-option>
            <el-option label="拼团模式-团长红包" :value="16"></el-option>
            <el-option label="免费模式-分享红包" :value="17"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="获奖时段">
          <el-date-picker
              v-model="searchForm.createtime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column label="所属活动">
        <template #default="s">{{s.row.active.title}}</template>
      </el-table-column>
      <el-table-column prop="content" label="奖励内容"></el-table-column>
      <el-table-column prop="nickname" label="获利用户">
        <template #default="s">
          <div v-if="s.row.member">
            {{s.row.member.nickname}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="from" label="奖励来源"></el-table-column>
      <el-table-column label="获奖时间">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "award",
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        type:0,
        time_between:[],
      },
      loading:false,
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    resetSearch(){
      this.searchForm = {
        nickname:"",
        type:0,
        time_between:[],
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.log.reward({
        nickname : this.searchForm.nickname,
        type:this.searchForm.type,
        time_between:this.searchForm.time_between,
        page : this.page
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>