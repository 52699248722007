<template>
  <transition name="fade">
  <div v-if="show" style="margin-top: .5rem;width: 100%">
    <el-card v-if="card"><slot></slot></el-card>
    <slot v-else></slot>
  </div>
  </transition>
</template>

<script>
export default {
  name: "YTabPane",
  data() {
    return {
      show: true,
      currentName: this.name
    };
  },
  props:{
    label:{
      type:String,
      default:"",
      required:true,
    },
    name:{
      type:String,
      default: "",
      required:true,
    },
    index:{
      type:Number,
      default:0,
    },
    card: {
      type:Boolean,
      default:false,
    },
  },
  watch: {
    label() {
      this.updateNav();
    },
    name(val) {
      this.currentName = val;
      this.updateNav();
    }
  },
  inject: ["TabsInstance"],
  computed: {},
  methods: {
    updateNav() {
      this.TabsInstance.updateNav();
    },
  },
  mounted() {
    this.updateNav();
  }
}
</script>

<style>
.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>