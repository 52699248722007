<template>
  <y_layout>
    <YTabs  v-model="active_tab">
      <YTabPane card label="支付日志" name="pay">
        <pay></pay>
      </YTabPane>
      <YTabPane card label="卡券日志" name="coupon">
        <coupon v-if="active_tab === 'coupon'"></coupon>
      </YTabPane>
      <YTabPane card label="奖励日志" name="award">
        <award v-if="active_tab === 'award'"></award>
      </YTabPane>
      <YTabPane card label="访问日志" name="visit">
        <visit v-if="active_tab === 'visit'"></visit>
      </YTabPane>
      <YTabPane card label="核销日志" name="cashier">
        <cashier v-if="active_tab === 'cashier'"></cashier>
      </YTabPane>
    </YTabs>
<!--    <el-tabs v-model="active_tab" type="border-card">-->
<!--      <el-tab-pane label="支付日志" name="pay">-->
<!--        <pay></pay>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="卡券日志" name="coupon">-->
<!--        <coupon v-if="active_tab === 'coupon'"></coupon>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="奖励日志" name="award">-->
<!--        <award v-if="active_tab === 'award'"></award>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="访问日志" name="visit">-->
<!--        <visit v-if="active_tab === 'visit'"></visit>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="核销日志" name="cashier">-->
<!--        <cashier v-if="active_tab === 'cashier'"></cashier>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import pay from "@/view/log/list/pay";
import coupon from "@/view/log/list/coupon";
import award from "@/view/log/list/award";
import visit from "@/view/log/list/visit";
import cashier from "@/view/log/list/cashier";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
export default {
  name: "index",
  components: {
    YTabPane,
    YTabs,
    y_layout,
    pay,
    coupon,
    award,
    visit,
    cashier,
  },
  data() {
    return {
      active_tab:"pay"
    }
  },
  mounted() {
    let tab = this.$route.query.tab;
    this.active_tab = tab || "pay";
  },
  methods: {

  }
}
</script>

<style scoped>

</style>