<template>
  <div>
    <el-collapse accordion>
      <el-collapse-item>
        <template #title>
          <i style="margin-left: 1rem;font-size: 1rem;font-weight: 600;margin-right: .5rem" class="el-icon-search"></i>筛选条件
        </template>
        <div class="flex-def flex-zCenter flex-cCenter">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="用户昵称">
              <el-input v-model="searchForm.nickname" placeholder="用户昵称"></el-input>
            </el-form-item>
            <el-form-item label="订单编号">
              <el-input v-model="searchForm.order_no" placeholder="订单编号"></el-input>
            </el-form-item>
            <el-form-item label="支付状态">
              <el-select v-model="searchForm.status">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="未支付" :value="1"></el-option>
                <el-option label="已支付" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付时间">
              <el-date-picker v-model="searchForm.time_between" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="order.active.title" width="200" label="所属活动">
      </el-table-column>
      <el-table-column prop="member.nickname" width="200" label="昵称">
      </el-table-column>
      <el-table-column label="头像" width="50">
        <template #default="s">
          <el-avatar shape="square" size="small" :src="s.row.member ? s.row.member.avatar : '' | tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="type" width="180" label="支付类型"></el-table-column>
      <el-table-column prop="order.from_member.nickname" width="200" label="推荐用户">
      </el-table-column>
      <el-table-column prop="uni_on_tid" width="200" label="支付编号"></el-table-column>
      <el-table-column prop="order.order_no" width="260" label="商户单号"></el-table-column>
      <el-table-column prop="transaction_id" width="260" label="交易单号"></el-table-column>
      <el-table-column prop="fee" label="金额" width="100"></el-table-column>
      <el-table-column label="支付状态">
        <template #default="s">{{s.row.status == 2? '已支付': '未支付'}}</template>
      </el-table-column>
      <el-table-column label="支付时间" width="180">
        <template #default="s">{{s.row.pay_time | date}}</template>
      </el-table-column>
      <el-table-column prop="order.active.agent.title" width="100" label="代理商" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order.agent_award_log.fee" :formatter="agentAwardLogFee" label="代理佣金" width="80"></el-table-column>
      <el-table-column label="创建时间" width="180">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "pay",
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        order_no:"",
        status:0,
        time_between:[],
      },
      loading:false,
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    agentAwardLogFee(item){
      return item.status === 2 ? item.order?.agent_award_log?.fee :''
    },
    resetSearch(){
      this.searchForm = {
        nickname:"",
        order_no:"",
        status:0,
        time_between:[],
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.log.pay({
        nickname : this.searchForm.nickname,
        status:this.searchForm.status,
        time_between:this.searchForm.time_between,
        order_no:this.searchForm.order_no,
        page : this.page
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>