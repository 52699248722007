<template>
  <div>
    <el-collapse accordion>
      <el-collapse-item>
        <template #title>
          <i style="margin-left: 1rem;font-size: 1rem;font-weight: 600;margin-right: .5rem" class="el-icon-search"></i>筛选条件
        </template>
        <div class="flex-def flex-zCenter flex-cCenter">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="用户昵称">
              <el-input v-model="searchForm.nickname" placeholder="用户昵称"></el-input>
            </el-form-item>
            <el-form-item label="卡券状态">
              <el-select v-model="searchForm.status">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="未过期" :value="1"></el-option>
                <el-option label="已过期" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="领券时间">
              <el-date-picker
                  v-model="searchForm.time_between"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button @click="load" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column width="200" label="昵称">
        <template #default="s">
          <div v-if="s.row.member">{{s.row.member.nickname}}</div>
        </template>
      </el-table-column>
      <el-table-column label="头像" width="50">
        <template #default="scope">
          <el-avatar shape="square" size="small" :src="scope.row.member.avatar | tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column width="200" label="所属活动">
        <template #default="s">
          <div v-if="s.row.active">{{s.row.active.title}}</div>
        </template>
      </el-table-column>
      <el-table-column width="200" label="所属商家">
        <template #default="s">
          <div v-if="s.row.merchant">{{s.row.merchant.title}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="title" width="200" label="卡券名称"></el-table-column>
      <el-table-column prop="total_num" width="200" label="可核销总量"></el-table-column>
      <el-table-column prop="last_use_num" width="200" label="已核销">
        <template #default="s">{{s.row.total_num-s.row.last_use_num}}</template>
      </el-table-column>
      <el-table-column prop="fee" width="200" label="面值"></el-table-column>
      <el-table-column prop="limit" width="200" label="核销限制"></el-table-column>
      <el-table-column prop="tips" width="200" label="使用门栏"></el-table-column>
      <el-table-column label="到期时间" width="180">
        <template #default="s">
          <div>{{s.row.time_end | date}}</div>
        </template>
      </el-table-column>
      <el-table-column label="领券时间" width="180">
        <template #default="s">
          <div>{{s.row.created_at | date}}</div>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "coupon",
  data(){
    return{
      page:1,
      size:15,
      total:0,
      list:[],
      searchForm:{
        nickname:"",
        status:0,
        time_between:[],
      },
      loading:false,
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    resetSearch(){
      this.searchForm = {
        nickname:"",
        status:0,
        time_between:[],
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.log.coupon({
        nickname : this.searchForm.nickname,
        status:this.searchForm.status,
        time_between:this.searchForm.time_between,
        page : this.page
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>